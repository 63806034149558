import * as React from 'react';
import './MenuGridButton.scss';
import { baseClassAnd } from '@Functions';
import { MenuGridSvg } from '@Svg';

interface MenuGridButtonProps {
    className?: string;
    text?: string;
    onClick: () => void;
}

const MenuGridButton: React.SFC<MenuGridButtonProps> = (props) => {
    return <button className={baseClassAnd('menu-grid-button', props)} onClick={props.onClick}><MenuGridSvg />{props.text}</button>
}

export default MenuGridButton;