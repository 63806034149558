import { Pedal, PedalMaker } from '@ViewModels';
import { ensureTrailingSlash } from '@Functions';
import * as xssFilters from 'xss-filters';

export function getProductStructuredData(pedal: Pedal, maker: PedalMaker) {
  return xssFilters.inHTMLData(JSON.stringify({
    "@context": "http://schema.org/",
    "@type": "Product",
    "@id": `https://www.pedaldudes.com${ensureTrailingSlash(pedal.route)}`,
    "name": pedal.name,
    "description": `${pedal.name} from ${(maker || {}).name}`,
  }));
}
