import * as React from "react";
import { Link } from "gatsby"
import "./PedalCategoryList.scss";
import { childClassClosure } from "@Functions";
import SearchBox from "components/search/SearchBox";
import RadioButton from "components/buttons/RadioButton";
import { PedalCategory, Pedal, PedalMaker } from "@ViewModels";
import bind from "bind-decorator";
import LinkWrapper from "components/texty/LinkWrapper";

const baseClass = 'pedal-category-list';
const childClass = childClassClosure(baseClass);

export type IProps = {
  categories: PedalCategory[];
  activeCategory?: PedalCategory;
}

interface IState {
}


export default class PedalCategoryList extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render() {
    return <nav className={baseClass}>
      <ul className={childClass('list')}>
        {this.props.categories.map((category, ci) =>
          <li key={ci}>
            <LinkWrapper to={category.route} className={childClass('link', this.props.activeCategory && this.props.activeCategory.route == category.route ? 'active' : '')}>
              <div className={childClass('image-wrapper')}>
                <img src={category.mainImage} alt={category.name} />
              </div>
              <h5 className={childClass('category-name')}>{category.name}</h5>
            </LinkWrapper>
          </li>
        )}
      </ul>
    </nav>
  }
}
