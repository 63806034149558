import * as React from 'react';
import './SocialButton.scss';
import { childClassClosure } from '@Functions';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const baseClass = 'social-button';
const childClass = childClassClosure(baseClass);

type SocialSiteName = "facebook" | "instagram" | "youtube";

const pageUrls = {
  youtube: 'https://www.youtube.com/channel/UCx677OZ_yRbLQh5x-slLndg',
  facebook: 'https://www.facebook.com/thepedaldudes',
  instagram: 'https://www.instagram.com/pedaldudes'
}

interface SocialButtonnProps {
  site: SocialSiteName;
}

const SocialButton: React.SFC<SocialButtonnProps> = (props) => {
  return <OutboundLink target="_blank" href={pageUrls[props.site]} className={`${baseClass} ${baseClass}--${props.site}`}>
  </OutboundLink>
}
export default SocialButton;
