import * as React from 'react';
import { childClassClosure, baseClassAnd } from '@Functions';
import "./PedalBrowser.scss";
import PedalMakerList, { IProps as PedalMakerListProps } from './PedalMakerList';
import PedalCategoryList, { IProps as PedalCategoryListProps } from './PedalCategoryList';
import bind from 'bind-decorator';
import CloseButton from 'components/buttons/CloseButton';
import MenuGridButton from 'components/buttons/MenuGridButton';
import { pedalCategorySortComparer } from '@ViewModels';

const navigatorBaseClass = 'pedal-browser-navigator';
const navigatorChildClass = childClassClosure(navigatorBaseClass);

const titleBarBaseClass = 'pedal-browser-title-bar';
const titleBarChildClass = childClassClosure(titleBarBaseClass);

type ClassableProps = {
  className?: string
}

type PedalBrowserNavigatorProps = PedalMakerListProps & PedalCategoryListProps & ClassableProps;

type PedalBrowserGridProps = ClassableProps & {
  children: JSX.Element[] | JSX.Element;
  canHide?: boolean;
  noTitleBar?: boolean;
}

type PedalBrowserContentProps = ClassableProps & {
  children: JSX.Element[] | JSX.Element;
}


type PedalBrowserTitleBarProps = ClassableProps & {
  title: string;
  brandLogoUrl?: string;
  controls?: JSX.Element;
}

type PedalBrowserGridState = {
  contextState: PedalBrowserContextProps;
}

// Displays the pedal browser as a three block grid, like on /browse-pedals
class PedalBrowserGrid extends React.Component<PedalBrowserGridProps, PedalBrowserGridState> {

  constructor(props: PedalBrowserGridProps) {
    super(props);
    this.state = {
      contextState: {
        showNavigator: !props.canHide,
        canHide: props.canHide,
        toggleNavigator: this.toggleNavigator
      }
    }
  }

  @bind
  toggleNavigator() {
    this.setState(prevState => ({
      contextState: {
        ...prevState.contextState,
        showNavigator: !this.state.contextState.showNavigator
      }
    }));
  }

  render() {
    return <PedalBrowserContext.Provider value={this.state.contextState}>
      <div className={baseClassAnd('pedal-browser-grid', this.props, this.props.noTitleBar ? 'no-title-bar' : undefined)}>
        {this.props.children}
      </div>
    </PedalBrowserContext.Provider>
  }
}

// Shows a back button, title and filter link (on mobile)
const PedalBrowserTitleBar: React.SFC<PedalBrowserTitleBarProps> = (props: PedalBrowserTitleBarProps) => {
  return <div className={baseClassAnd(titleBarBaseClass, props)}>
    <h1 className={titleBarChildClass('pedal-name')}>{props.title}</h1>
    {props.controls}
    {props.brandLogoUrl && <div className={titleBarChildClass('brand-logo')}><img src={props.brandLogoUrl} alt={props.title} /></div>}
  </div>
}


// The main content inside the grid
const PedalBrowserContent: React.SFC<PedalBrowserContentProps> = (props: PedalBrowserContentProps) => {
  return <div className={baseClassAnd('pedal-browser-content', props)}>
    {props.children}
  </div>
}

const leadInBlockClassName = 'pedal-browser-lead-in-block';

type PedalBrowserContextProps = {
  canHide: boolean;
  showNavigator: boolean;
  toggleNavigator: () => void;
};

const PedalBrowserContext = React.createContext<PedalBrowserContextProps>({
  showNavigator: true,
  canHide: false,
  toggleNavigator: () => { }
});


class PedalBrowserNavigatorButton extends React.Component<ClassableProps, {}> {

  static contextType = PedalBrowserContext;
  context!: React.ContextType<typeof PedalBrowserContext>;

  @bind
  onClick() {
    this.context.toggleNavigator();
  }

  render() {
    return <MenuGridButton className={baseClassAnd('pedal-browser-navigator-button', this.props)} onClick={this.onClick} text="Categories" />
  }
}

class PedalBrowserNavigator extends React.Component<PedalBrowserNavigatorProps, {}> {

  static contextType = PedalBrowserContext;
  context!: React.ContextType<typeof PedalBrowserContext>;

  constructor(props: PedalBrowserNavigatorProps) {
    super(props);
  }

  @bind
  toggleNavigator() {
    this.context.toggleNavigator();
  }

  public render() {
    const sortedCategories = this.props.categories.concat().sort(pedalCategorySortComparer);
    return <div className={baseClassAnd(navigatorBaseClass, this.props)}>
      <div className={navigatorChildClass('container', this.context.showNavigator ? 'shown' : 'hidden-mobile', this.context.canHide ? 'can-hide' : '')}>
        <div className={navigatorChildClass('modal-bar')}>
          <CloseButton onClick={this.toggleNavigator} />
        </div>
        <h3 className={navigatorChildClass('section-title')}>Manufacturers</h3>
        <PedalMakerList makers={this.props.makers} activeMaker={this.props.activeMaker} />
        <h3 className={navigatorChildClass('section-title')}>Categories</h3>
        <PedalCategoryList categories={sortedCategories} activeCategory={this.props.activeCategory} />
      </div >
    </div>
  }
}

export {
  PedalBrowserGrid,
  PedalBrowserTitleBar,
  PedalBrowserContent,
  leadInBlockClassName,
  PedalBrowserNavigator,
  PedalBrowserNavigatorButton
};