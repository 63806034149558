import * as React from 'react';
import './CloseButton.scss';
import { baseClassAnd } from '@Functions';
import { CrossSvg } from '@Svg';

interface CloseButtonProps {
  className?: string;
  onClick: () => void;
}

const CloseButton: React.SFC<CloseButtonProps> = (props) => {
  return <button className={baseClassAnd('close-button', props)} onClick={props.onClick}><CrossSvg /></button>
}

export default CloseButton;
