import * as React from "react";
import { Link } from "gatsby"
import "./PedalMakerList.scss";
import { childClassClosure } from "@Functions";
import SearchBox from "components/search/SearchBox";
import RadioButton from "components/buttons/RadioButton";
import { PedalCategory, Pedal, PedalMaker } from "@ViewModels";
import bind from "bind-decorator";
import LinkWrapper from "components/texty/LinkWrapper";

const baseClass = 'pedal-maker-list';
const childClass = childClassClosure(baseClass);

export type IProps = {
  makers: PedalMaker[];
  activeMaker?: PedalMaker;
}

interface IState {
}


export default class PedalMakerList extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render() {
    return <nav className={baseClass}>
      <ul className={childClass('list')}>
        {this.props.makers.map((maker, i) =>
          <li key={i}>
            <LinkWrapper to={maker.route} className={childClass('link', this.props.activeMaker && this.props.activeMaker.route == maker.route ? 'active' : '')}>
              <img src={maker.mainImage} alt={maker.name} />
              {/* <span>{maker.name}</span> */}
            </LinkWrapper>
          </li>
        )}
      </ul>
    </nav>
  }
}
