import * as React from "react";
import { Link, StaticQuery } from "gatsby"
import { childClassClosure } from "@Functions";
import "./SiteFooter.scss";
import SearchBox from "components/search/SearchBox";
import { graphql } from "gatsby"
import { SiteSearchIndex } from "@GraphQLModels";
import SubscribeForm from "components/forms/SubscribeForm";
import SocialButton from "components/buttons/SocialButton";
import LinkWrapper from "components/texty/LinkWrapper";

const baseClass = 'site-footer';
const childClass = childClassClosure(baseClass);

const SiteFooter: React.SFC<{}> = (props: {}) => {

  return <footer className={baseClass}>
    <section className={childClass('engagement-section')}>
      <div className={childClass('container')}>
        <div className={childClass('engagement-section-social')}>
          <div className={childClass('text-block')}>
            <h4>Follow us!</h4>
            <p>Pedal Dudes on social media</p>
          </div>
          <ul className={childClass('engagement-section-social-icon-list')}>
            <li><SocialButton site={"facebook"} /></li>
            <li><SocialButton site={"instagram"} /></li>
            <li><SocialButton site={"youtube"} /></li>
          </ul>
        </div>
        <div className={childClass('engagement-section-subscribe')}>
          <div className={childClass('text-block')}>
            <h4>Subscribe to our newsletter</h4>
            <p>For the latest news and reviews</p>
          </div>
          <SubscribeForm />
        </div>
      </div>
    </section>
    <section className={childClass('info-section')}>
      <nav className={[childClass('container'), childClass('info-section-nav')].join(' ')}>
        <LinkWrapper to="/" className='unimportant'>Home</LinkWrapper>
        <LinkWrapper to="/privacy-policy">Privacy</LinkWrapper>
        <LinkWrapper to="/cookie-policy">Cookies</LinkWrapper>
        <LinkWrapper to="/contact-us">Contact</LinkWrapper>
      </nav>
    </section>
  </footer>
}
export default SiteFooter;
