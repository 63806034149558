import * as React from 'react';
import './MdxBlock.scss';
import { childClassClosure, baseClassAnd } from '@Functions';
import { Mdx } from '@GraphQLModels';
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import LinkWrapper from './LinkWrapper';

const baseClass = 'mdx-block';
const childClass = childClassClosure(baseClass);


interface IProps {
  className?: string;
  showTitle?: boolean;
  subTitle?: JSX.Element;
  mdx: Mdx;
}


const components = {
  a: LinkWrapper
}


const MdxBlock: React.SFC<IProps> = (props: IProps) => {

  return <section className={baseClassAnd(baseClass, props)}>
    {props.showTitle && <h1 className={childClass('main-title')}>{props.mdx.frontmatter.title}</h1>}
    {props.subTitle}
    <MDXRenderer components={components}>{props.mdx.code.body}</MDXRenderer>
  </section >
}
export default MdxBlock;
