import * as React from "react";
import { childClassClosure } from "@Functions";
import "./SubscribeForm.scss";

const baseClass = 'subscribe-form';
const childClass = childClassClosure(baseClass);

const SubscribeForm: React.SFC<{}> = () => {

  return <form className={baseClass} name="mailinglist" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value="mailinglist" />
    <input className={childClass('email-input')} type="email" name="email" placeholder="your email" required />
    <button className={childClass('submit-button')} type="submit">Subscribe</button>
  </form>
}
export default SubscribeForm;
