import React from "react"
import { Link, graphql } from "gatsby"
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { Mdx, FileConnection, MdxConnection } from "@GraphQLModels";
import { Pedal, PedalMaker, PedalCategory } from "@ViewModels";
import { PedalBrowserGrid, PedalBrowserNavigator, PedalBrowserTitleBar, leadInBlockClassName, PedalBrowserContent, PedalBrowserNavigatorButton } from "components/pedal-browser/PedalBrowser";
import MdxBlock from "components/texty/MdxBlock";
import PedalCardList from "components/pedal-browser/PedalCardList";
import MultiImageViewer from "components/media/MultiImageViewer";
import RelatedPedalsList from "components/explorers/RelatedPedalsList";
import SiteFooter from "components/footer/SiteFooter";
import PageMeta from "components/texty/PageMeta";
import { getProductStructuredData } from "components/structured-data/ProductStructuredData";
import { removeTrailingSlash } from "@Functions";

interface PedalProductPageProps {
  pageContext: {
    id: string,
    makerSlug: string,
    makerRegex: string,
    firstCategory: string
  };
  data: {
    mdx: Mdx,
    allMdx: MdxConnection,
    categoryPedals: MdxConnection,
    makerPedals: FileConnection
  }
}

export default function PedalProductPage(props: PedalProductPageProps) {
  const makers = props.data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'maker').map(x => PedalMaker.fromMdxNode(x.node));
  const categories = props.data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'category').map(x => PedalCategory.fromMdxNode(x.node));
  const imageUrls = props.data.mdx.frontmatter.images > 0
    ? Array.from(Array(props.data.mdx.frontmatter.images).keys()).map(index => `/assets/pedals${removeTrailingSlash(props.data.mdx.fields.route)}-${index + 1}.png`)
    : [];

  const thisPedalCategory = categories.find(x => x.route == `/${props.pageContext.firstCategory}/`);
  const thisPedalMaker = makers.find(x => x.route == `/${props.pageContext.makerSlug}/`);
  const sameCategoryPedals = props.data.categoryPedals.edges.map(x => Pedal.fromMdxNode(x.node));
  const sameMakerPedals = props.data.makerPedals.edges.map(x => Pedal.fromFileNode(x.node));
  const pedal = Pedal.fromMdxNode(props.data.mdx);
  return (
    <div className={baseClass}>
      <PageMeta
        title={props.data.mdx.frontmatter.title}
        route={props.data.mdx.fields.route}
        imageUrl={imageUrls[0]} >
        <meta property="og:type" content="product" />
        <script className='structured-data-list' type="application/ld+json">{getProductStructuredData(pedal, thisPedalMaker)}</script>
      </PageMeta>
      <SiteHeader />
      <PedalBrowserGrid className={childClass('container')} canHide={true}>
        <PedalBrowserTitleBar
          title={props.data.mdx.frontmatter.title}
          brandLogoUrl={`/assets/media/${props.pageContext.makerSlug}.png`}
          controls={<PedalBrowserNavigatorButton />} />
        <section className={leadInBlockClassName}>
          <MultiImageViewer alt={props.data.mdx.frontmatter.title} imageUrls={imageUrls} />
          <MdxBlock mdx={props.data.mdx} showTitle={false} />
        </section>
        <PedalBrowserNavigator
          makers={makers}
          activeMaker={makers.find(x => x.route == `/${props.pageContext.makerSlug}/`)}
          categories={categories}
          activeCategory={undefined}
        />
        <PedalBrowserContent>
          {thisPedalCategory &&
            <RelatedPedalsList
              pedals={sameCategoryPedals}
              heading={`Other ${thisPedalCategory.name} pedals`}
              displayMaker={true}
              className={childClass('full-width')} />}
          <RelatedPedalsList
            pedals={sameMakerPedals}
            heading={`More pedals from ${thisPedalMaker.name}`}
            className={childClass('full-width')} />
        </PedalBrowserContent>
      </PedalBrowserGrid>
      <SiteFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query PedalProductPageLayout($id: String, $firstCategory: String, $makerRegex: String) {
      mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          images
          categories
          mainImage
        }
        code {
          body
        }
      }
      categoryPedals: allMdx( 
          limit: 5
          filter: { frontmatter: { categories: { eq: $firstCategory } } } 
        ) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
            }
          }
        }
      }
      makerPedals: allFile( 
        limit: 5
        filter: { dir: { regex: $makerRegex } } 
      ) {
        edges {
          node {
            name
            relativeDirectory
            childMdx {
              id
              frontmatter {
                title
              }
            }
          }
        }
      }
      allMdx( filter: { frontmatter: { templateKey: { in: [ "maker", "category" ] } } } ) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              templateKey
              mainImage
              title
            }
          }
        }
      }
    }
  `
