import * as React from "react";
import "./MultiImageViewer.scss";
import { childClassClosure, baseClassAnd } from "@Functions";
import bind from "bind-decorator";

const baseClass = 'multi-image-viewer';
const childClass = childClassClosure(baseClass);

export type IProps = {
  alt: string;
  imageUrls: string[];
  className?: string;
}

interface IState {
  selectedIndex: number;
}


export default class MultiImageViewer extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedIndex: 0
    }
  }

  @bind
  onThumbnailClick(index: number) {
    this.setState({
      selectedIndex: index
    });
  }

  public render() {
    return <div className={baseClassAnd(baseClass, this.props)}>
      <div className={childClass('main', this.props.imageUrls.length <= 1 ? 'only-image' : '')}>
        <img src={this.props.imageUrls[this.state.selectedIndex]} alt={this.props.alt} />
      </div>
      {this.props.imageUrls.length > 1 &&
        <ul className={childClass('thumbnail-list')}>
          {this.props.imageUrls.map((url, i) =>
            <li key={i} className={childClass('thumbnail-list-item')} onClick={() => this.onThumbnailClick(i)}>
              <img src={url} alt={this.props.alt} />
            </li>
          )}
        </ul>
      }
    </div>
  }
}
